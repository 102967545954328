<template>
<div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" style="width:80%;margin-left:20px;">
        <el-form-item label="文章标题" prop="title">
            <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="文章内容" prop="content">
            <quillEditor
                :options="editorOption"
                v-model="form.content">
        </quillEditor>
        </el-form-item>
        <div style="margin-top:150px;">
            <el-button type="primary" @click="onSubmit('form')">立即创建</el-button>
        </div>
    </el-form>
</div>

</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  pushArticle
} from '@/api/TeacherApi.js'
export default {
  components: {
    quillEditor
  },
  data () {
    return {
      form: {
        title: '',
        content: ''
      },
      loading: false,
      editorOption: {
        placeholder: '请输入正文'
      },
      rules: {
        title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onSubmit (formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          pushArticle(this.form).then(res => {
            if (res.status.code == 1) {
              this.$message.success('发布成功！')
              this.form.content = '';
              this.form.title = '';
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .ql-container{
    height: 300px ;
  }
</style>
